import {
  Box,
  Button,
  Dialog,
  DialogContent,
  IconButton,
  Typography,
  TextField,
  InputAdornment,
  makeStyles,
  FormHelperText,
} from "@material-ui/core";
import React, { useState } from "react";
import { CgClose } from "react-icons/cg";
import LoadingComp from "./LoadingComp";
import { RiLockPasswordFill } from "react-icons/ri";
import { Formik, Form } from "formik";
import * as yup from "yup";
import { HiEye, HiEyeOff } from "react-icons/hi";
import { toast } from "react-hot-toast";
import ApiConfig from "../ApiConfig/ApiConfig";
import Axios from "axios";

const useStyles = makeStyles((theme) => ({
  mainBox: {
    "& span": {
      color: "#EB7D2D",
      cursor: "pointer",
    },
    iconsclass1: {
      color: "#ffffff !important",
      "& .MuiIconButton-root": {
        color: "#fff !important",
      },
    },
  },
}));

export default function ResetPasswordModal({
  handleClose,
  confirmModal,
  resetToken,
}) {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const initialValues = {
    newPassword: "",
  };

  const validationSchema = yup.object().shape({
    newPassword: yup
      .string()
      .trim()
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()\-_=+{}[\]|;:'",.<>/?])[a-zA-Z\d!@#$%^&*()\-_=+{}[\]|;:'",.<>/?]{8,}$/,
        "Password must contain 8 characters, one uppercase, one lowercase, one number and one special case character."
      )
      .required("Please enter password.")
      .min(6, "Please enter atleast 6 characters.")
      .max(18, "You can enter only 18 characters."),
  });

  const handleSubmitDataFun = async (values) => {
    try {
      setIsLoading(true);
      const response = await Axios({
        method: "PUT",
        url: `${ApiConfig.resetPassword}/${resetToken}`,
        data: {
          password: values.newPassword,
        },
      });
      if (response.data.statusCode === 200) {
        toast.success(response?.data?.responseMessage);
        setIsLoading(false);
        handleClose();
        window.open("/dashboard", "_self");
      } else {
        setIsLoading(false);
        toast.error(response?.data?.responseMessage);
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      toast.error(error?.response?.data?.responseMessage);
    }
  };

  return (
    <>
      <Dialog
        open={confirmModal}
        onClose={() => !isLoading && handleClose()}
        fullWidth
        maxWidth="xs"
      >
        <IconButton
          style={{ position: "absolute", right: "0px" }}
          disabled={isLoading}
          onClick={() => handleClose()}
        >
          <CgClose style={{ color: "#908f8e", fontSize: "18px" }} />
        </IconButton>
        <DialogContent>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values) => handleSubmitDataFun(values)}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              touched,
              values,
              setFieldValue,
            }) => (
              <Form>
                <Box
                  align="center"
                  mb={2}
                  p={3}
                  className={classes.iconsclass1}
                >
                  <Typography variant="h5">RESET PASSWORD</Typography>
                  <Box mt={2} align="start">
                    <Typography variant="body1">New Password</Typography>
                    <TextField
                      variant="outlined"
                      fullWidth
                      type={showPassword ? "text" : "password"}
                      name="newPassword"
                      placeholder="Enter password"
                      value={values.newPassword}
                      error={Boolean(touched.newPassword && errors.newPassword)}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      disabled={isLoading}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <IconButton edge="center">
                              <img src="./images/passlock.png" alt="passlock" />
                            </IconButton>
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              edge="center"
                              onClick={() => setShowPassword(!showPassword)}
                              style={{ color: "#fff" }}
                            >
                              {showPassword ? (
                                <HiEye
                                  style={{ color: "#fff", fontSize: "18px" }}
                                />
                              ) : (
                                <HiEyeOff
                                  style={{ color: "#fff", fontSize: "18px" }}
                                />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <FormHelperText error>
                      {touched.newPassword && errors.newPassword}
                    </FormHelperText>
                  </Box>

                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    type="submit"
                    style={{ marginTop: "20px" }}
                    disabled={isLoading}
                  >
                    RESET PASSWORD {isLoading && <LoadingComp />}
                  </Button>
                </Box>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </>
  );
}
