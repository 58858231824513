import {
  Box,
  Button,
  Dialog,
  DialogContent,
  IconButton,
  Typography,
  makeStyles,
} from "@material-ui/core";
import React from "react";
import { CgClose } from "react-icons/cg";
import LoadingComp from "./LoadingComp";

const useStyles = makeStyles((theme) => ({
  mainRoot: {
    "& .MuiDialog-paperWidthXs": {
      padding: "25px !important",
    },

    "& .MuiDialogContent-root": {
      padding: "4px 0px",
    },
    "& img": {
      width: "80px",
      height: "80px",
    },
    "& .contentClx": {
      background: "#1D1F23",
      padding: "12px",
      "& p": {
        fontSize: "11px",
        color: "rgba(255, 255, 255, 0.60)",
      },
    },
  },
}));

export default function ConfirmModal({
  text,
  handleClose,
  confirmModal,
  handleSubmit,
  isLoading,
  title,
}) {
  const classes = useStyles();

  return (
    <Dialog
      open={confirmModal}
      onClose={() => !isLoading && handleClose()}
      fullWidth
      maxWidth="xs"
      className={classes.mainRoot}
    >
      <IconButton
        style={{ position: "absolute", right: 0, top: 0 }}
        disabled={isLoading}
        onClick={() => handleClose()}
      >
        <CgClose style={{ color: "#908f8e", fontSize: "18px" }} />
      </IconButton>
      <DialogContent>
        <Box align="center" mb={2}>
          <img src="images/deleteAccountLogout.png" alt="" />
          <Box mb={2}>
            <Typography variant="body1">{text}</Typography>
          </Box>
          {title === "account" && (
            <Box className="contentClx" mb={2}>
              <Typography variant="body1">
                You will permanently lose access to your account, any remaining
                balance, pending tip and/or withdrawal, and investments it may
                have and all its information, such as deposit or withdrawal
                histories. Deleted accounts cannot be restored under any
                circumstances.
              </Typography>
            </Box>
          )}
          <Button
            variant="contained"
            color="secondary"
            onClick={handleClose}
            disabled={isLoading}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            style={{ marginLeft: "10px" }}
            disabled={isLoading}
          >
            {title === "Fee Management"
              ? "Submit"
              : title === "account"
              ? "Permanently delete account"
              : "Logout"}{" "}
            {isLoading && <LoadingComp />}
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
