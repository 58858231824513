import React, { useEffect, useState } from "react";
import TableComp from "./TableComp";
import { getAPIHandler } from "../ApiConfig/service";
import Axios from "axios";
import moment from "moment";
import CrashGameModal from "./CrashGameModal";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { sortAddress } from "../utils";
import CopyToClipboard from "react-copy-to-clipboard";
import { IconButton } from "@material-ui/core";
import { toast } from "react-hot-toast";
import { MdContentCopy } from "react-icons/md";
const tableHead = [
  {
    heading: "Time",
  },
  {
    heading: "Crash",
  },
  {
    heading: "Hash",
  },
  {
    heading: "Action",
  },
];
export default function CommonTable() {
  const [storeViewData, setStoreViewData] = useState({});
  const [openViewModal, setOpenViewModal] = useState(false);
  const [isCrashLoading, setIsCrashLoading] = useState(true);
  const [crashHistoryData, setCrashHistoryData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [crashPage, setCrashPage] = useState(1);
  const [noOfCrashPages, setNoOfCrashPages] = useState(1);
  const [noOfTotalCrashPages, setNoOfTotalCrashPages] = useState(1);
  const getCrashHistoryListApi = async (source) => {
    try {
      const response = await getAPIHandler({
        endPoint: "listHistoryGame",
        paramsData: {
          page: crashPage,
          limit: 10,
        },
        source: source,
      });
      if (response.data.statusCode === 200) {
        setCrashHistoryData(response.data.responseResult?.docs);
        setNoOfCrashPages(response.data?.responseResult?.pages);
        setNoOfTotalCrashPages(response.data?.responseResult?.total);
      } else {
        setCrashHistoryData([]);
      }
      setIsCrashLoading(false);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setIsCrashLoading(false);
      setCrashHistoryData([]);
    }
  };
  useEffect(() => {
    const source = Axios.CancelToken.source();
    getCrashHistoryListApi(source);
    return () => {
      source.cancel();
    };
  }, [crashPage]);
  return (
    <div>
      <TableComp
        tableHead={tableHead}
        scoreListData={
          crashHistoryData &&
          crashHistoryData?.map((item, i) => ({
            Time: moment(item.createdAt).format("lll"),
            Crash: (
              <span style={{ color: item?.crashValue < 2 ? "red" : "green" }}>
                {`${item?.crashValue}x`}
              </span>
            ),
            Hash: (
              <>
                <span> {`${item?.hash && sortAddress(item?.hash)}x`}</span>
                &nbsp;
                <CopyToClipboard text={item && item?.hash}>
                  <IconButton onClick={() => toast.success("Copied")}>
                    <MdContentCopy style={{ width: "17px" }} />
                  </IconButton>
                </CopyToClipboard>
              </>
            ),

            Action: [
              {
                icon: <VisibilityIcon fontSize="inherit" />,
                onClick: () => {
                  setStoreViewData(item);
                  setOpenViewModal(true);
                },
              },
            ],
          }))
        }
        noOfPages={noOfCrashPages}
        noOfTotalPages={noOfTotalCrashPages}
        page={crashPage}
        setPage={setCrashPage}
        limit={10}
        isLoading={isLoading}
        NoDataFoundText="No crash history found"
      />
      {openViewModal && (
        <CrashGameModal
          handleClose={() => setOpenViewModal(false)}
          confirmModal={setOpenViewModal}
          storeViewData={storeViewData}
        />
      )}
    </div>
  );
}
