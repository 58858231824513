import {
  Box,
  Dialog,
  DialogContent,
  IconButton,
  Typography,
  makeStyles,
  TextField,
} from "@material-ui/core";
import axios from "axios";

import React, { useState, useEffect, useMemo, useContext } from "react";
import TableComp from "./TableComp";
import { CgClose } from "react-icons/cg";
import { getAPIHandler } from "../ApiConfig/service";
import { TiDocumentText } from "react-icons/ti";
import moment from "moment";
import CrashGameModal from "./CrashGameModal";
import { AuthContext } from "../context/Auth";
import CommonTable from "./CommonTable";

const useStyles = makeStyles((theme) => ({
  mainRoot: {
    "& .MuiDialog-paperWidthSm": {
      padding: "25px !important",
    },
    "& .displayStart": {
      borderBottom: "1px solid #484949",
      paddingBottom: "12px",
      "& svg": {
        fontSize: " 17px",
      },
    },
    "& .MuiDialogContent-root": {
      padding: "4px 0px",
    },
    "& .mainBox": {
      background: "#1D1F23",
      padding: "12px",
    },
    "& .selectBox": {
      width: "140px",
    },
    "& .selectBox": {
      width: "140px",
      "& .MuiAutocomplete-inputRoot .MuiAutocomplete-input": {
        padding: "1.5px 4px",
      },
      "& .MuiSvgIcon-root": {
        color: "#fff",
      },
    },
  },
}));

export default function CashHistoryModal({ handleClose, confirmModal }) {
  const classes = useStyles();
  const auth = useContext(AuthContext);

  return (
    <>
      <Dialog
        open={confirmModal}
        onClose={() => !auth?.isCrashLoading && handleClose()}
        fullWidth
        maxWidth="sm"
        className={classes.mainRoot}
      >
        <IconButton
          style={{ position: "absolute", right: 0, top: 0 }}
          disabled={auth?.isCrashLoading}
          onClick={() => handleClose()}
        >
          <CgClose style={{ color: "#908f8e", fontSize: "18px" }} />
        </IconButton>
        <Box className="displayStart">
          <TiDocumentText />
          &nbsp;&nbsp;
          <Typography variant="h5">Crash History</Typography>
        </Box>
        <DialogContent>
          <Box className="mainBox" mt={1}>
            <CommonTable />
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}
